
.vue-cropper[data-v-3defeb46] {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  direction: ltr;
  touch-action: none;
  text-align: left;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}
.cropper-box[data-v-3defeb46],
.cropper-box-canvas[data-v-3defeb46],
.cropper-drag-box[data-v-3defeb46],
.cropper-crop-box[data-v-3defeb46],
.cropper-face[data-v-3defeb46] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.cropper-box-canvas img[data-v-3defeb46] {
  position: relative;
  text-align: left;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transform: none;
  max-width: none;
  max-height: none;
}
.cropper-box[data-v-3defeb46] {
  overflow: hidden;
}
.cropper-move[data-v-3defeb46] {
  cursor: move;
}
.cropper-crop[data-v-3defeb46] {
  cursor: crosshair;
}
.cropper-modal[data-v-3defeb46] {
  background: rgba(0, 0, 0, 0.5);
}
.cropper-crop-box[data-v-3defeb46] {
  /*border: 2px solid #39f;*/
}
.cropper-view-box[data-v-3defeb46] {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.cropper-view-box img[data-v-3defeb46] {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: left;
  max-width: none;
  max-height: none;
}
.cropper-face[data-v-3defeb46] {
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.1;
}
.crop-info[data-v-3defeb46] {
  position: absolute;
  left: 0px;
  min-width: 65px;
  text-align: center;
  color: white;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
}
.crop-line[data-v-3defeb46] {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.line-w[data-v-3defeb46] {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}
.line-a[data-v-3defeb46] {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}
.line-s[data-v-3defeb46] {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}
.line-d[data-v-3defeb46] {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}
.crop-point[data-v-3defeb46] {
  position: absolute;
  width: 8px;
  height: 8px;
  opacity: 0.75;
  background-color: #39f;
  border-radius: 100%;
}
.point1[data-v-3defeb46] {
  top: -4px;
  left: -4px;
  cursor: nw-resize;
}
.point2[data-v-3defeb46] {
  top: -5px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}
.point3[data-v-3defeb46] {
  top: -4px;
  right: -4px;
  cursor: ne-resize;
}
.point4[data-v-3defeb46] {
  top: 50%;
  left: -4px;
  margin-top: -3px;
  cursor: w-resize;
}
.point5[data-v-3defeb46] {
  top: 50%;
  right: -4px;
  margin-top: -3px;
  cursor: e-resize;
}
.point6[data-v-3defeb46] {
  bottom: -5px;
  left: -4px;
  cursor: sw-resize;
}
.point7[data-v-3defeb46] {
  bottom: -5px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}
.point8[data-v-3defeb46] {
  bottom: -5px;
  right: -4px;
  cursor: se-resize;
}
@media screen and (max-width: 500px) {
.crop-point[data-v-3defeb46] {
    position: absolute;
    width: 20px;
    height: 20px;
    opacity: 0.45;
    background-color: #39f;
    border-radius: 100%;
}
.point1[data-v-3defeb46] {
    top: -10px;
    left: -10px;
}
.point2[data-v-3defeb46],
  .point4[data-v-3defeb46],
  .point5[data-v-3defeb46],
  .point7[data-v-3defeb46] {
    display: none;
}
.point3[data-v-3defeb46] {
    top: -10px;
    right: -10px;
}
.point4[data-v-3defeb46] {
    top: 0;
    left: 0;
}
.point6[data-v-3defeb46] {
    bottom: -10px;
    left: -10px;
}
.point8[data-v-3defeb46] {
    bottom: -10px;
    right: -10px;
}
}
