.parcel[data-v-bad3fe7a] {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
[data-v-bad3fe7a] .el-upload-dragger {
  width: 100px !important;
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
[data-v-bad3fe7a] .el-upload--text {
  border: 1px solid #fff;
  width: 101px;
  height: 101px;
}
.el-image-viewer__wrapper[data-v-bad3fe7a] {
  z-index: 9999 !important;
  /* 设置一个较高的 z-index 值 */
}
.imaPreview[data-v-bad3fe7a] {
  width: 60px !important;
  height: 60px !important;
}
.avatar-uploader[data-v-bad3fe7a] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-uploader .el-upload[data-v-bad3fe7a] {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload[data-v-bad3fe7a]:hover {
  border-color: #409eff;
}
.avatar-uploader-icon[data-v-bad3fe7a] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar[data-v-bad3fe7a] {
  width: 178px;
  height: 178px;
  display: block;
}
.ImageList[data-v-bad3fe7a] {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 50%;
  height: 150px;
  margin-top: 20px;
}
.eleImg[data-v-bad3fe7a] {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  cursor: pointer;
  margin: 0px 10px;
}
.shade[data-v-bad3fe7a] {
  border-radius: 6px;
  margin: 0px 10px;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.parentLevel[data-v-bad3fe7a] {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 120px !important;
}
.IconStyle[data-v-bad3fe7a] {
  color: #ffffff;
  font-size: 20px;
}
[data-v-bad3fe7a] .el-image__preview {
  -o-object-fit: contain;
     object-fit: contain;
  background-color: #f2f2f2;
}
