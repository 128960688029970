.header{
    background-color: #242f42;
}
.login-wrap{
    background: #324157;
}
.plugins-tips{
    background: #eef1f6;
}
.plugins-tips a{
    color: #20a0ff;
}
.el-upload--text em {
    color: #20a0ff;
}
.pure-button{
    background: #20a0ff;
}
.tags-li.active {
    border: 1px solid #409EFF;
    background-color: #409EFF;
}
.message-title{
    color: #20a0ff;
}
.collapse-btn:hover{
    background: rgb(40,52,70);
}