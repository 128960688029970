.cropper-content[data-v-b3549c4a] {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
}
.cropper-content .cropper-box[data-v-b3549c4a] {
  flex: 1;
  width: 100%;
}
.cropper-content .cropper-box .cropper[data-v-b3549c4a] {
  width: auto;
  height: 300px;
}
.cropper-content .show-preview[data-v-b3549c4a] {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}
.cropper-content .show-preview .preview[data-v-b3549c4a] {
  overflow: hidden;
  border: 1px solid #67c23a;
  background: #cccccc;
}
.footer-btn[data-v-b3549c4a] {
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
}
.footer-btn .scope-btn[data-v-b3549c4a] {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  padding-right: 10px;
}
.footer-btn .upload-btn[data-v-b3549c4a] {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}
.footer-btn .btn[data-v-b3549c4a] {
  outline: none;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  margin-right: 10px;
}
